import { useCallback, useEffect, useState } from "react";
import { PortalSession as PortalSessionComponent, RewardResource, VoucherResource } from "@leatcom/portal-sessions";
import { getContact, getItemsForLocation, reverseRewardReception, reverseVoucherRedemption } from "../../api";
import { ApplyTo, LSKItem } from "../../types";
import { useApp } from "../../context";
import { Error, Modal } from "../../components";
import { addItemToCurrentAccount, getItemIdentifiersFromSku, onContactLink } from "./helpers";

export default function PortalSession() {
    const { apiKey, shopUuid } = useApp();

    const [items, setItems] = useState<Array<LSKItem>>([]);
    const [contactUuid, setContactUuid] = useState<string>();

    const [currentAccountTotalAmount, setCurrentAccountTotalAmount] = useState<number>(0);
    const [currentDiscount, setCurrentDiscount] = useState<string>("");
    const [currentItems, setCurrentItems] = useState<Array<LSKItem>>([]);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const [initialized, setInitialized] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const initialize = useCallback(async () => {
        await initializeCurrentAccount();
        await initializeItems();

        setInitialized(true);
    }, []);

    useEffect(() => {
        (async () => await initialize())();
    }, [initialize]);

    const initializeCurrentAccount = async () => {
        window.pos_getCurrentAccount((response: any) => {
            if (response.data.consumer && response.data.consumer.email) {
                getContact(response.data.consumer.email).then(({ data }) => setContactUuid(data.uuid));
            }
            if (response.data.totalAmount) {
                setCurrentAccountTotalAmount(response.data.totalAmount);
            }
        });
    };

    const initializeItems = async () => {
        const { data } = await getItemsForLocation(posContext.locationId);

        if (Array.isArray(data)) {
            setItems(data);
        } else {
            setError("Items could not be fetched.");
        }
    };

    const onClaimReward = (r: RewardResource, transactionUuid?: string) => {
        const onReverse = async () => {
            setError(`Can't map SKUs to identifiers. Reversing reward reception for reward: ${r.title}`);
            transactionUuid && (await reverseRewardReception(transactionUuid));
        };

        processTransaction(r.lsk_items as string, r.lsk_discount as string, r.lsk_apply_to as ApplyTo, onReverse);
    };

    const onRedeemVoucher = (v: VoucherResource) => {
        const onReverse = async () => {
            setError(`Can't map SKUs to identifiers. Reversing redemption of voucher with code: ${v.code}`);
            await reverseVoucherRedemption(v.code);
        };

        processTransaction(v.attributes.lsk_items, v.attributes.lsk_discount, v.attributes.lsk_apply_to, onReverse);
    };

    const processTransaction = (
        lskItems: string,
        discount: string,
        applyTo: ApplyTo,
        onReverse: () => void,
    ) => {
        discount && setCurrentDiscount(discount);

        if (lskItems) {
            const lskSkus = (lskItems as string).split(";");

            const { lskItemIdentifiers, unidentifiedSkus } = getItemIdentifiersFromSku(lskSkus, items);

            if (unidentifiedSkus.length) {
                onReverse();
                return;
            }

            if (lskItemIdentifiers.length > 1) {
                setCurrentItems(items.filter((item) => lskItemIdentifiers.includes(item.id.toString())));
                setModalOpen(true);
            } else {
                const discountCode: string | null = applyTo === ApplyTo.ITEMS ? discount : null;
                addItemToCurrentAccount(lskItemIdentifiers[0], discountCode);
            }
        }

        if (applyTo === ApplyTo.ORDER && currentAccountTotalAmount) {
            window.pos_toggleGlobalDiscount(discount);
        }
    };

    return (
        initialized && (
            <>
                <PortalSessionComponent
                    host={process.env.REACT_APP_API_HOST}
                    apiKey={apiKey!}
                    shopUuid={shopUuid!}
                    contactUuid={contactUuid}
                    enableEmailIdentification
                    onContactLink={onContactLink}
                    onClaimReward={onClaimReward}
                    onRedeemVoucher={onRedeemVoucher}
                />

                <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} canClose={false} title={"Items"}>
                    <div className="flex min-w-72 flex-col gap-2">
                        {currentItems.map((item) => (
                            <button
                                key={item.sku}
                                type="button"
                                className="rounded-lg border border-gray-300 bg-white px-5 py-3 text-sm font-medium text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-100"
                                onClick={() => {
                                    addItemToCurrentAccount(item.id.toString(), currentDiscount);
                                    setModalOpen(false);
                                }}
                            >
                                {item.name}
                            </button>
                        ))}
                    </div>
                </Modal>

                <Error message={error} />
            </>
        )
    );
}
