import axios, { AxiosInstance } from "axios";
import { errorHandler } from "./error";

class HttpInstance {
    initialized: boolean = false;
    instance: AxiosInstance = axios.create();

    initialize(apiKey: string): void {
        this.instance = axios.create({
            baseURL: process.env.REACT_APP_API_HOST,
            headers: {
                Authorization: `Bearer ${apiKey}`,
            },
        });

        this.instance.interceptors.response.use(undefined, errorHandler);

        this.initialized = true;
    }
}

export const http: HttpInstance = new HttpInstance();
