import { getItemsForLocation, reverseRewardReception, reverseVoucherRedemption } from "../../api";
import { useApp } from "../../context";

// Web extension dimensions: height: 648, width: 972

export default function BrowserSession() {
    const { apiKey, shopUuid } = useApp();

    const getItems = async () => {
        const { data: items } = await getItemsForLocation("1204678196985858");
        console.log("items :>>", items);
    };

    const reverseReward = async () => {
        await reverseRewardReception("50d058e0-c0ce-462d-b1a3-9bbef2c86e7f");
    };

    const reverseVoucher = async () => {
        await reverseVoucherRedemption("2");
    };

    return (
        <div>
            <p>{apiKey}</p>
            <p>{shopUuid}</p>
            <div className="flex gap-2">
                <button className="rounded-lg bg-blue-600 px-4 py-2 text-white" onClick={getItems}>
                    Items
                </button>
                <button className="rounded-lg bg-blue-600 px-4 py-2 text-white" onClick={reverseReward}>
                    Reverse reward
                </button>
                <button className="rounded-lg bg-blue-600 px-4 py-2 text-white" onClick={reverseVoucher}>
                    Reverse voucher
                </button>
            </div>
        </div>
    );
}
