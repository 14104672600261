import { AxiosResponse } from "axios";
import { http } from "../HttpInstance";

const endpoint = "/api/v1/integrations/ls-k-series/reverse";

export const reverseRewardReception = async (transactionUuid: string): Promise<AxiosResponse<void>> => {
    return http.instance.post(`${endpoint}/reward-reception/${transactionUuid}`);
}

export const reverseVoucherRedemption = async (voucherCode: string): Promise<AxiosResponse<void>> => {
    return http.instance.post(`${endpoint}/voucher/${voucherCode}`);
}