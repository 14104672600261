import { Link } from "react-router-dom";
import logo from './assets/ls-logo.png';

export default function App() {
    return (
        <div className="text-center">
            <header className="bg-[#282c34] min-h-screen flex flex-col gap-6 items-center justify-center ">
                <img src={logo} className="h-[40vmin] pointer-events-none logo" alt="logo"/>

                <p className="text-white text-3xl">LightSpeed K-Series integration</p>
                <Link to={"/portal"} className="bg-white rounded px-4 py-2 text-xl">Start Portal Session</Link>
            </header>
        </div>
    );
}


