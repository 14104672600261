import { useEffect } from "react";
import { http } from "./api";
import { AppProvider } from "./context";
import { useRetryUntilResolved } from "./hooks";
import { Router } from "./routes";
import { Error } from "./components";

export default function Root() {
    const apiKey = new URLSearchParams(window.location.search).get("apiKey");
    const shopUuid = new URLSearchParams(window.location.search).get("shopUuid");

    const resolved = useRetryUntilResolved(() => typeof posContext !== "undefined" && http.initialized);

    useEffect(() => {
        if (apiKey) {
            http.initialize(apiKey);
        }
    }, [apiKey]);

    if (!apiKey || !shopUuid) {
        return <Error message={"An api key and/or shop uuid was not provided. Please check the web extension configuration in the Lightspeed (K-Series) dashboard."} />;
    }

    return (
        resolved && (
            <AppProvider apiKey={apiKey} shopUuid={shopUuid}>
                <Router />
            </AppProvider>
        )
    );
}
