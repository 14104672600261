import { useState } from "react";
import { useInterval } from "./index";

export default function useRetryUntilResolved(callback: () => boolean, interval: number = 100) {
    const [hasResolved, setHasResolved] = useState(false);

    useInterval(
        () => {
            const result = callback();
            if (result) {
                setHasResolved(true);
            }
        },
        hasResolved ? undefined : interval
    );

    return hasResolved;
}
