import { createContext, ReactNode, useContext } from "react";

export type AppContextType = {
    apiKey: string;
    shopUuid: string;
};

export const AppContext = createContext<AppContextType | null>(null);

const AppProvider = ({ children, apiKey, shopUuid }: { children: ReactNode; } & AppContextType) => {
    return (
        <AppContext.Provider
            value={{ apiKey, shopUuid }}
        >
            {children}
        </AppContext.Provider>
    );
};

export const useApp = (): AppContextType => {
    const context = useContext(AppContext) as AppContextType;
    if (context === null) {
        throw new Error("Only use 'useSession' within a SessionProvider");
    }
    return context;
};

export default AppProvider;
