import { AxiosError } from "axios";
import { toast } from "react-toastify";

export type ApiError = {
    code: number;
    message: string;
    status_code: number;
    errors?: ErrorObject;
};

export type ErrorObject = {
    [key: string]: string[];
};

export const errorHandler = (error: AxiosError<ApiError, never>) => {
    const statusCode: number | undefined = error.response?.status;

    if (statusCode && statusCode !== 401) {
        const errors: ErrorObject | undefined = error.response?.data.errors;
        const content = (
            <div>
                <p>{error.response?.data.message}</p>
                {errors &&
                    Object.keys(errors).map((key: string) =>
                        errors[key].map((error: string) => <li key={error}>{error}</li>),
                    )}
            </div>
        );

        toast.error(content);
    }

    return Promise.reject(error);
};
