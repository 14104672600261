import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { PortalSession, GiftcardSession, BrowserSession } from "../modules";
import App from "../App";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Outlet />,
        children: [
            {
                index: true,
                element: <App />,
            },
            {
                path: "portal",
                element: <PortalSession />,
            },
            {
                path: "giftcards",
                element: <GiftcardSession />,
            },
            {
                path: "browser",
                element: <BrowserSession />,
            },
        ],
    },
]);

const Router = () => <RouterProvider router={router} />;

export default Router;
