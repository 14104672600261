import { ContactResource } from "@leatcom/portal-sessions";
import { LSKItem } from "../../types";

export const onContactLink = (contact: ContactResource) => {
    window.pos_setConsumer({
        email: contact.email,
        firstName: contact.current_display_values.firstname,
        lastName: contact.current_display_values.lastname,
    });

    window.pos_addExternalReference(contact.uuid, "contact");
};

export const addItemToCurrentAccount = (itemIdentifier: string, discountCode: string | null) => {
    window.pos_getCurrentAccount((response: any) => {
        const existingItem = response.data.transactionLines?.find(
            (line: any) => line.itemIdentifier === itemIdentifier && line.quantity === 100 && !line.discounts.length,
        );
        if (existingItem) {
            const lineIdentifier = existingItem.identifier;
            window.pos_toggleDiscount(discountCode, lineIdentifier);
        } else {
            window.pos_addItemToCurrentAccount(itemIdentifier);
            window.pos_getCurrentAccount((response: any) => {
                if (response.data.transactionLines.length) {
                    const lineIdentifier =
                        response.data.transactionLines[response.data.transactionLines.length - 1].identifier;
                    window.pos_toggleDiscount(discountCode, lineIdentifier);
                }
            });
        }
    });
};

export const getItemIdentifiersFromSku = (skus: Array<string>, items: Array<LSKItem>) => {
    let lskItemIdentifiers: Array<string> = [];
    let unidentifiedSkus: Array<string> = [];

    for (const sku of skus) {
        const item = items.find((item) => item.sku === sku);
        if (item) {
            lskItemIdentifiers.push(item.id.toString());
        } else {
            unidentifiedSkus.push(sku);
        }
    }

    return {
        lskItemIdentifiers,
        unidentifiedSkus,
    };
};
