import { useEffect, useState } from "react";
import { PortalType, PortalSession, GiftcardTransactionResource } from "@leatcom/portal-sessions";
import { useApp } from "../../context";

export default function GiftcardSession() {
    const { apiKey, shopUuid } = useApp();

    const [amountInCents, setAmountInCents] = useState<number>();

    useEffect(() => {
        if (!amountInCents) {
            window.pos_getCurrentAccount((response: any) => {
                setAmountInCents(response.data.totalAmount - response.data.paidAmount);
            });
        }
    }, [amountInCents]);

    if (!amountInCents) {
        return <>Nothing in account</>;
    }

    return (
        <PortalSession
            host={process.env.REACT_APP_API_HOST}
            type={PortalType.REDEEM_GIFTCARD}
            amountInCents={amountInCents}
            apiKey={apiKey}
            shopUuid={shopUuid}
            onUseGiftcard={(transaction: GiftcardTransactionResource) => {
                window.pos_addPayment(Math.abs(transaction.amount_in_cents), "LGC");
                if (Math.abs(transaction.amount_in_cents) >= amountInCents) {
                    window.pos_close();
                }
            }}
            allowMultipleGiftcards
        />
    );
}
