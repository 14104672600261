import { KeyboardEvent, ReactNode, useEffect, useRef, useState } from "react";

type ModalProps = {
    title?: string;
    isOpen: boolean;
    onClose?: () => void;
    children: ReactNode;
    canClose?: boolean;
};

export default function Modal({ title, isOpen, onClose, children, canClose = true }: ModalProps) {
    const [open, setOpen] = useState<boolean>(isOpen);
    const modalRef = useRef<HTMLDialogElement | null>(null);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        const modalElement = modalRef.current;
        if (modalElement) {
            if (open) {
                modalElement.show();
            } else {
                modalElement.close();
            }
        }
    }, [open]);
    const handleClose = () => {
        if (onClose && canClose) {
            onClose();
        }
        setOpen(false);
    };
    const handleKeyDown = (event: KeyboardEvent<HTMLDialogElement>) => {
        if (event.key === "Escape" && canClose) {
            handleClose();
        }
    };

    return (
        <div
            className={`fixed inset-0 z-40 min-h-screen items-center bg-gray-500 bg-opacity-25 ${
                !open ? "hidden" : "flex"
            }`}
        >
            <dialog
                ref={modalRef}
                onKeyDown={handleKeyDown}
                className="scrollbar-hide absolute max-w-3xl overflow-auto rounded-lg drop-shadow-md"
            >
                <div className="flex w-full items-center justify-between bg-gray-50 p-4">
                    <p className="truncate text-lg">{title}</p>
                    {canClose && (
                        <div
                            className="h-[25px] w-[25px] cursor-pointer opacity-50 hover:opacity-90"
                            onClick={handleClose}
                        >
                            X
                        </div>
                    )}
                </div>

                <div className="p-6">{children}</div>
            </dialog>
        </div>
    );
}
